export default class MockedExtraConfigurator{
    constructor() {}
    public addExtra(){}
    public isValidConfig(){
        return true;
    }
    public removeExtra(){}
    public toJson(){
        return {};
    }
    public fromJson(){}
}