import {CSSProperties} from "react";


export default (({id, className, height, style = {}, disabled, checked}: {id?: string, checked?: boolean, disabled?: boolean, height: string, className?: string, style?: Omit<CSSProperties, 'position'>})=>{
    return (
        <label tabIndex={0} style={{...style, position: 'absolute'}} id={`${id}-label`} className={className}>
            <input disabled={disabled} type="checkbox" checked={checked} id={id} style={{opacity: 0}}/>
            {checked ? <div className={'icon-tick-right'} style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width:'100%',
                textAlign:'center',
                lineHeight: height,
                fontSize: style.fontSize ?? 'inherit',
                color: 'black'
            }}></div> : null}
        </label>
    );
});