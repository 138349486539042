import { makeKeyboardEvent } from '#events';
import { capitalize, getTranslation, px2vh, px2vw, translate } from '#utils';
import { useEffect, useLayoutEffect, useState } from 'react';
import { match, P } from 'ts-pattern';
import { classes } from '../../../stylesheet.js';
import type { CSSProperties, FC } from 'react';
import type { Components, Extras, Layouts, Signals } from '../../../types.js';
import { printPrice } from '../../../utils.js';

const Checkbox = (({ id, className, height, style = {}, checked = false }) => {
	return (
		<label tabIndex={0} style={{ ...style, position: 'absolute' }} id={`${id}-label`} className={className}>
			{checked ? (
				<div
					className={'icon-tick-right'}
					style={{
						position: 'absolute',
						top: '0',
						left: '0',
						width: '100%',
						textAlign: 'center',
						lineHeight: height,
						fontSize: style.fontSize ?? 'inherit',
						color: 'black',
					}}
				></div>
			) : null}
		</label>
	);
}) as FC<{
	id?: string;
	className?: string;
	height?: string;
	style?: Omit<CSSProperties, 'position'>;
	disabled?: boolean;
	checked?: boolean;
}>;

const ComboboxOption = (({ i, injected, templating, selectedOptions, extra, canSelectMore, currencyCode }) => {
	const keydownHandler = makeKeyboardEvent(['ENTER'], () => {
		const isOptionSelected = selectedOptions.includes(extra.options.choices[i]!.id);
		if (!canSelectMore && !isOptionSelected) return;
		const newConfig = isOptionSelected
			? selectedOptions.filter((x) => x !== extra.options.choices[i]!.id)
			: selectedOptions.concat(extra.options.choices[i]!.id);
		injected.setSelectedOptions(newConfig);
	});

	const price =
		sessionStorage.getItem('pricesIncludeTax') === 'true'
			? extra.options.choices[i]!.priceWithTax
			: extra.options.choices[i]!.priceWithoutTax;

	return (
		<div
			className={'navigable'}
			id={`extra-${extra.id}-choice-${i}`}
			onKeyDown={keydownHandler}
			style={{
				...classes('relative', 'rounded'),
				height: `${px2vh(128)}`,
				width: `${px2vw(503)}vw`,
				marginTop: i === 0 ? `${px2vh(56)}vh` : `${px2vh(32)}vh`,
				paddingTop: `${px2vh(16)}vh`,
				paddingBottom: `${px2vh(16)}vh`,
				marginLeft: 'auto',
				marginRight: 'auto',
				background:
					canSelectMore || selectedOptions.includes(extra.options.choices[i]!.id) ? '#FFF' : '#E9EDF0',
				boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
			}}
		>
			<div
				style={{
					fontSize: `${px2vw(28)}vw`,
					marginLeft: `${px2vw(24)}vw`,
					marginBottom: `${px2vh(10)}vh`,
					fontWeight: 'bold',
					width: '75%',
					color:
						canSelectMore || selectedOptions.includes(extra.options.choices[i]!.id) ? undefined : '#76889B',
				}}
			>
				{getTranslation(
					extra.options.choices[i]!.translations,
					templating.languageCode,
					templating.projectLanguageCode
				)}
			</div>
			<div
				style={{
					fontSize: `${px2vw(24)}vw`,
					marginLeft: `${px2vw(24)}vw`,
				}}
			>
				{printPrice(price / 100, currencyCode)}
			</div>
			<Checkbox
				id={`extra-${extra.id}-${i}-input`}
				style={{
					...classes('rounded'),
					width: `${px2vw(40)}vw`,
					height: `${px2vw(40)}vw`,
					backgroundColor: '#F5F6F8',
					border: '2px solid #D3DAE1',
					top: `${px2vh(30)}vh`,
					right: `${px2vw(36)}vw`,
					color:
						canSelectMore || selectedOptions.includes(extra.options.choices[i]!.id) ? undefined : '#91A3B5',
				}}
				height={`${px2vw(40)}vw`}
				checked={selectedOptions.includes(extra.options.choices[i]!.id)}
				disabled={!canSelectMore}
			></Checkbox>
		</div>
	);
}) as FC<{
	extra: Extras.Combobox;
	canSelectMore: boolean;
	currencyCode: string;
	selectedOptions: string[];
	injected: {
		setSelectedOptions: (opts: string[]) => void;
	};
	templating: Parameters<Layouts.Details>[0]['templating'];
	i: number;
}>;

const ComboboxModalAddButton = ({ injected, templating }) => {
	return (
		<div
			onClick={injected.onSubmit}
			className={'navigable text-center'}
			id={'add-combobox-button'}
			style={{
				...classes('absolute', 'w-full', 'rounded'),
				border: '0.1vw solid rgb(211, 218, 225)',
				color: '#576575',
				bottom: `-${px2vh(64 + 62)}vh`,
				height: `${px2vh(64)}vh`,
			}}
		>
			<span
				style={{
					...classes('rounded'),
					fontSize: `${px2vw(32)}vw`,
					width: '100%',
					textAlign: 'center',
					lineHeight: `${px2vh(64)}vh`,
				}}
			>
				{capitalize(translate(templating.texts, 'Add extra'))}
			</span>
		</div>
	);
};

const ComboboxSelectionModalBody = (({ extra, injected, selectedOptions, currencyCode, signals, templating }) => {
	const canSelectMore =
		extra.options.max === 0 ? true : !!extra.options.max && selectedOptions.length < extra.options.max;
	useEffect(() => {
		signals.focus.value.stack(`extra-${extra.id}-choice-0`);
		return () => signals.focus.value.unstack();
	}, []);
	const handler = makeKeyboardEvent(['BACK'], () => {
		injected.onBack();
		return { stopPropagation: true, preventDefault: true };
	});
	return (
		<div onKeyDown={handler} style={{ ...classes('relative', 'h-full') }}>
			<div style={{ height: `${px2vh(549)}vh`, overflowY: 'scroll', scrollBehavior: 'smooth' }}>
				{extra.options.choices.map((_, i) => {
					const navMap = {
						up: i === 0 ? 'btn-exit' : `extra-${extra.id}-choice-${i - 1}`,
						down:
							i === extra.options.choices.length - 1
								? 'add-combobox-button'
								: `extra-${extra.id}-choice-${i + 1}`,
					};
					return (
						<injected.Navigable navMap={navMap}>
							<ComboboxOption
								selectedOptions={selectedOptions}
								extra={extra}
								templating={templating}
								currencyCode={currencyCode}
								canSelectMore={canSelectMore}
								i={i}
								injected={injected}
							/>
						</injected.Navigable>
					);
				})}
				<injected.Navigable navMap={{ up: `extra-${extra.id}-choice-${extra.options.choices.length - 1}` }}>
					<ComboboxModalAddButton injected={injected} templating={templating} />
				</injected.Navigable>
			</div>
		</div>
	);
}) as FC<{
	extra: Extras.Combobox;
	currencyCode: string;
	selectedOptions: string[];
	injected: {
		configurator: Extras.Configurator;
		setSelectedOptions: (opts: string[]) => void;
		onBack: () => void;
		onSubmit: () => void;
		VerticalList: Components.List;
		Navigable: Components.Navigable;
	};
	signals: {
		focus: Signals.Focus;
		cart: Signals.Cart;
		config: Signals.ExtraConfig;
	};
	templating: Parameters<Layouts.Details>[0]['templating'];
}>;

export default (({ data, idList, injected, id, style, signals, templating }) => {
	const [isModalVisible, setModalVisible] = useState<boolean>(false);
	const selectedOptions: Extras.Config.Combobox = (signals.config.value[data.extra.id] as string[]) ?? [];
	const [initialOptions, setInitialOptions] = useState(
		(signals.config.peek()[data.extra.id] ?? []) as Extras.Config.Combobox
	);

	const setSelectedOptions = (opts: Extras.Config.Combobox) => {
		injected.configurator.addExtra(data.extra, opts);
		signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
	};
	const onModalBack = () => {
		setSelectedOptions(initialOptions);
		setModalVisible(false);
	};
	const onSubmit = () => {
		signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
		setModalVisible(false);
	};
	const onKeyDown = makeKeyboardEvent(['ENTER'], () => {
		setModalVisible(!isModalVisible);
		if (!isModalVisible) {
			setInitialOptions((signals.config.peek()[data.extra.id] ?? []) as Extras.Config.Combobox);
		}
		return { stopPropagation: true, preventDefault: true };
	});
	const onModalKeyDown = makeKeyboardEvent(['ARROW_DOWN', 'HOME', 'ARROW_UP'], (key) => {
		if (key === 'ARROW_DOWN' && signals.focus.value.current === 'btn-exit') {
			signals.focus.value.replace(`extra-${data.extra.id}-choice-0`);
		}
		return { stopPropagation: true, preventDefault: true };
	});
	const backOverride = makeKeyboardEvent(['BACK'], () => {
		setModalVisible(false);
		return { stopPropagation: isModalVisible, preventDefault: isModalVisible };
	});
	const subtitle = match(data.extra.options)
		.with({ min: P.number.and(P.not(0)), max: P.number.and(P.not(0)) }, (options) =>
			options.min !== options.max
				? `${translate(templating.texts, 'select-min-max-extras')
						.replace('{min}', options.min.toString())
						.replace('{max}', options.max.toString())}`
				: `${translate(
						templating.texts,
						options.max === 1 ? 'select-x-extras-singular' : 'select-x-extras-plural'
				  ).replace('{units}', options.max.toString())}`
		)
		.with(
			{ min: P.number.and(P.not(0)) },
			(options) =>
				`${translate(
					templating.texts,
					options.min === 1 ? 'select-min-extra-singular' : 'select-min-extra-plural'
				).replace('{min}', options.min.toString())}`
		)
		.with(
			{ max: P.number.and(P.not(0)) },
			(options) =>
				`${translate(
					templating.texts,
					options.max === 1 ? 'select-max-extra-singular' : 'select-max-extra-plural'
				).replace('{max}', options.max.toString())}`
		)
		.otherwise(() => '');

	useLayoutEffect(() => {
		const extraConfigInCart = signals?.cart?.value?.[
			JSON.parse(localStorage.getItem('orderToken') || '[]')
		]?.filter((product) => window.location.pathname.indexOf(product.timestamp) > -1)?.[0]?.config;
		let selectedOpts: string[] = [];
		data?.extra?.options?.choices.forEach((choice) => {
			if (!choice.available) {
				return;
			}
			//@ts-ignore
			if (extraConfigInCart?.[data.extra.id]?.find((option) => option === choice.id)) {
				selectedOpts = selectedOpts.concat(choice.id);
			}
		});

		if (selectedOpts?.length > 0) {
			setSelectedOptions(selectedOpts);
			injected.configurator.addExtra(data.extra, selectedOpts);
			signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
		}
	}, []);
	const extrasPrice: number = ((signals.config.value[data.extra.id] as string[]) ?? [])
		.map((x) => data.extra.options.choices.find((y) => y.id === x))
		.reduce(
			(acc, cur) =>
				acc +
				(sessionStorage.getItem('pricesIncludeTax') === 'true' ? cur!.priceWithTax : cur!.priceWithoutTax),
			0
		);
	const numSelected = ((signals.config.value[data.extra.id] as string[]) ?? []).length;
	const currentOffset = idList.indexOf(id);
	const navMap = {
		up: currentOffset > 0 ? idList[currentOffset - 1].toString() : undefined,
		down: currentOffset !== idList.length - 1 ? idList[currentOffset + 1].toString() : undefined,
	};

	return (
		<injected.Navigable navMap={navMap}>
			<div onKeyDown={backOverride}>
				{isModalVisible ? (
					<injected.Navigable
						navMap={{
							up: 'searchNext',
							down: 'searchNext',
							left: 'stopPropagation',
							right: 'stopPropagation',
						}}
					>
						<div onKeyDown={onModalKeyDown}>
							<injected.Modal
								title={getTranslation(
									data.extra.translations,
									templating.languageCode,
									templating.projectLanguageCode
								)}
								subtitle={subtitle}
								body={
									<ComboboxSelectionModalBody
										signals={signals}
										templating={templating}
										selectedOptions={selectedOptions}
										extra={data.extra}
										injected={{ ...injected, setSelectedOptions, onBack: onModalBack, onSubmit }}
										currencyCode={data.options.currency_code}
									/>
								}
								width={`${px2vw(736)}vw`}
								height={`${px2vw(1008)}vw`}
								backBtn={true}
								backAction={onModalBack}
								firstFocus={`extra-${data.extra.id}-choice-0`}
							/>
						</div>
					</injected.Navigable>
				) : null}
				<div
					id={id}
					className={'navigable'}
					onKeyDown={onKeyDown}
					style={{
						...classes('rounded', 'm-8', 'p-4', 'relative'),
						boxShadow: '0px 4px 16px 0px #95979A40',
						textAlign: 'left',
						...style,
						color: '#2E3843',
					}}
				>
					<div style={{ ...classes('inline-block'), width: 'calc(100% - 2.5rem)' }}>
						<div
							style={{
								...classes('bold'),
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{getTranslation(
								data.extra.translations,
								templating.languageCode,
								templating.projectLanguageCode
							)}
						</div>
						{data.extra.options.min === 0 && numSelected === 0 ? (
							<div>{translate(templating.texts, 'Not selected')}</div>
						) : data.extra.options.min && (numSelected === 0 || numSelected < data.extra.options.min) ? (
							<div>{translate(templating.texts, 'Required')}</div>
						) : (
							<div>
								{translate(
									templating.texts,
									`x-selected-${numSelected === 1 ? 'singular' : 'plural'}`
								).replace('{units}', numSelected)}{' '}
								({printPrice(extrasPrice / 100, data.options.currency_code)})
							</div>
						)}
					</div>
					<div
						className="icon icon-chevron-right"
						style={{
							height: '2rem',
							width: '2rem',
							fontSize: '2rem',
							position: 'absolute',
							top: 'calc(50% - 1rem)',
							right: '1.5rem',
						}}
					/>
				</div>
			</div>
		</injected.Navigable>
	);
}) as FC<{
	data: {
		extra: Extras.Combobox;
		options: { currency_code: string };
	};
	id?: string;
	idList: (string | number)[];
	injected: {
		configurator: Extras.Configurator;
		Modal: Components.Modal;
		Navigable: Components.Navigable;
		VerticalList: Components.List;
	};
	signals: {
		focus: Signals.Focus;
		cart: Signals.Cart;
		config: Signals.ExtraConfig;
	};
	style: CSSProperties;
	templating: Parameters<Layouts.Details>[0]['templating'];
}>;
