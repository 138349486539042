import {RadioInput} from "#inputs";
import {px2vh, px2vw} from "#utils";
import {useEffect, useState} from "react";
import {classes} from "../../stylesheet.js";
import type {FC} from "../../types.js";

const Carousel: FC<{ images: string[] }> = ({images})=>{
    const [current, setCurrent] = useState(0);
    const multipleImages = images && images.length > 1;
    useEffect(()=>{
        const intervalHandle = multipleImages? setInterval(()=>setCurrent((current+1) % images.length), 5000) : undefined;
        return ()=>clearInterval(intervalHandle);
    });
    return  images && images.length ? (
        <div ref={(ref)=>{if (ref){ref.style.setProperty("overflow", "visible", "important");}}} style={{ ...classes('relative'), marginTop: `${px2vh(24)}vh`, height: `${px2vh(448)}vh`, width: `${px2vw(512)}vw`, marginLeft: `${px2vw(72)}vw`, borderRadius: '4px', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url("${images[current]}")`, float: 'left' }}>
            {multipleImages?
            <div style={{ ...classes('text-center', 'absolute', 'w-full'), height: '1rem', bottom: `calc(-${px2vh(16)}vh - ${px2vw(16)}vw)` }}>
                {
                    images.map((x, i) => <RadioInput key={x} name={"carousel"} style={{ display: 'inline-block', marginLeft: i !== 0 ? `${px2vw(12)}vw` : 0, width: `${px2vw(16)}vw`, height: `${px2vw(16)}vw`, borderRadius: '50%', borderColor: '#2E3843', borderWidth: '1px', borderStyle: 'solid', backgroundColor: i !== current ? 'transparent' : '#2E3843' }} />)
                }
            </div>
            :null}
        </div>
    ) : null;
};

export default Carousel;