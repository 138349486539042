export default {
	milk: {
		icon: 'icon-milk',
		color: 'rgba(100, 57, 128, 1)',
	},
	molluscs: {
		icon: 'icon-molluscs',
		color: 'rgba(103, 200, 219, 1)',
	},
	gluten: {
		icon: 'icon-gluten',
		color: 'rgba(210, 172, 106, 1)',
	},
	eggs: {
		icon: 'icon-eggs',
		color: 'rgba(249, 172, 86, 1)',
	},
	celery: {
		icon: 'icon-celery',
		color: 'rgba(144, 200, 75, 1)',
	},
	crustaceans: {
		icon: 'icon-crustaceans',
		color: 'rgba(146, 82, 69, 1)',
	},
	lupins: {
		icon: 'icon-lupins',
		color: 'rgba(241, 218, 106, 1)',
	},
	mustard: {
		icon: 'icon-mustard',
		color: 'rgba(193, 166, 97, 1)',
	},
	nuts: {
		icon: 'icon-nuts',
		color: 'rgba(193, 111, 99, 1)',
	},
	peanuts: {
		icon: 'icon-peanuts',
		color: 'rgba(188, 142, 106, 1)',
	},
	sesame: {
		icon: 'icon-sesame',
		color: 'rgba(171, 161, 141, 1)',
	},
	sesame_seeds: {
		icon: 'icon-sesame',
		color: 'rgba(171, 161, 141, 1)',
	},
	soy: {
		icon: 'icon-soy',
		color: 'rgba(54, 179, 126, 1)',
	},
	sulphites: {
		icon: 'icon-sulfur-dioxide-sulphites',
		color: 'rgba(133, 49, 91, 1)',
	},
	sulphurdioxide_sulphites: {
		icon: 'icon-sulfur-dioxide-sulphites',
		color: 'rgba(133, 49, 91, 1)',
	},
	so2: {
		icon: 'icon-sulfur-dioxide-sulphites',
		color: 'rgba(133, 49, 91, 1)',
	},
	fish: {
		icon: 'icon-fish',
		color: 'rgba(65, 113, 181, 1)',
	},
} as const;
