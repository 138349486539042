import type {HTMLProps} from "react";
import {classes} from "../stylesheet.js";
import type {FC} from "../types.js";


export default (({id, value, style })=>{
    return (
        <div tabIndex={0} style={{...classes('relative', 'inline-block', 'rounded'), ...style}}>
            <input id={id} value={value as string || 0} readonly style={{...classes('t-0', 'l-0', 'text-center', 'border-0', 'rounded', 'absolute'), width: '100%', height: '100%', fontSize: style?.fontSize || 'unset', color: style?.color || 'unset'}} className="navigable"/>
        </div>
    );
}) as FC<HTMLProps<HTMLInputElement>>;

