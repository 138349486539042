import { z } from "zod";
import type { Components, Extras, Layouts, Signals } from "../../../types.js";
import { ZodExtraOutput } from "./types.js";
import Normal from "./normal.js";
import Numeric from "./numeric.js";
import Combobox from "./combobox.js";
import Multicombobox from "./multicombobox.js";
import type { CSSProperties, FC } from "react";
import React from "react";

export default (({ data, injected, idList, style, signals, templating }) => {
    if (data.extra.type === 'normal')
        return <Normal id={`extra-${data.extra.id}`} idList={idList} data={{ extra: data.extra, options: data.options }} signals={signals} injected={injected} style={style} templating={templating} />;
    if (data.extra.type === 'numeric')
        return <Numeric id={`extra-${data.extra.id}`} idList={idList} data={{ extra: data.extra, options: data.options }} signals={signals} injected={injected} style={style} templating={templating}/>;
    if (data.extra.type === 'combobox')
        return <Combobox id={`extra-${data.extra.id}`} idList={idList} data={{ extra: data.extra as any, options: data.options }} signals={signals} injected={injected} style={style} templating={templating} />;
    //(data.extra.type === 'multicombobox')
    return <Multicombobox id={`extra-${data.extra.id}`} idList={idList} data={{ extra: data.extra, options: data.options }} signals={signals} injected={injected} style={style} templating={templating} />;
}) as FC<{
    data: {
        extra: z.infer<typeof ZodExtraOutput>,
        options: { currency_code: string }
    },
    idList: (string | number)[]
    injected: {
        configurator: Extras.Configurator,
        Modal: Components.Modal,
        VerticalList: Components.List,
        Navigable: Components.Navigable
    },
    signals: {
        config: Signals.ExtraConfig,
        focus: Signals.Focus
        cart: Signals.Cart
    },
    style: CSSProperties,
    templating: Parameters<Layouts.Details>[0]["templating"]
}>