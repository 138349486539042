import applyClasses from './css.js';
import type { CSSProperties } from './types.js';

const margins = {
	'm-8': {
		margin: '2rem',
	},
	'my-4': {
		marginTop: '1rem',
		marginBottom: '1rem',
	},
	'my-auto': {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	'mx-2': {
		marginLeft: '0.5rem',
		marginRight: '0.5rem',
	},
	'mx-auto': {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	'mt-4': {
		marginTop: '1vh',
	},
	'mt-6': {
		marginTop: '1.5vh',
	},
	'mt-8': {
		marginTop: '2vh',
	},
	'mr-8': {
		marginRight: '2rem',
	},
	'ml-4': {
		marginLeft: '1rem',
	},
	'ml-8': {
		marginLeft: '2rem',
	},
	'mb-2': {
		marginBottom: '2vh',
	},
	'mb-4': {
		marginBottom: '1vh',
	},
	'mb-6': {
		marginBottom: '1.5vh',
	},
} as const satisfies Record<string, CSSProperties>;
const paddings = {
	'p-2': {
		padding: '0.5rem',
	},
	'p-4': {
		padding: '1rem',
	},
	'p-8': {
		padding: '2rem',
	},
	'pb-4': {
		paddingBottom: '1rem',
	},
	'py-2': {
		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
	},
	'py-4': {
		paddingTop: '1rem',
		paddingBottom: '1rem',
	},
	'px-4': {
		paddingLeft: '1rem',
		paddingRight: '1rem',
	},
	'px-8': {
		paddingLeft: '2rem',
		paddingRight: '2rem',
	},
	'pb-8': {
		paddingBottom: '2rem',
	},
} as const satisfies Record<string, CSSProperties>;
const positioning = {
	relative: {
		position: 'relative',
	},
	absolute: {
		position: 'absolute',
	},
	'b-0': {
		bottom: 0,
	},
	't-0': {
		top: 0,
	},
	'l-0': {
		left: 0,
	},
	'r-0': {
		right: 0,
	},
} as const satisfies Record<string, CSSProperties>;
const dimensions = {
	'w-full': {
		width: '100%',
	},
	'w-1/2': {
		width: '50%',
	},
	'h-full': {
		height: '100%',
	},
	'h-1/2': {
		height: '50%',
	},
	'h-1/3': {
		height: '33.3333%',
	},
	'h-0': {
		height: 0,
	},
} as const;
const texts = {
	nowrap: {
		whiteSpace: 'nowrap',
	},
	'text-center': {
		textAlign: 'center',
	},
	'text-right': {
		textAlign: 'right',
	},
	'text-xl': {
		fontSize: '1.25rem',
		lineHeight: '1.75rem',
	},
	'text-1xl': {
		fontSize: '1.35rem',
		lineHeight: '1.75rem',
	},
	'text-2xl': {
		fontSize: '1.5rem',
		lineHeight: '2rem',
	},
	'text-3xl': {
		fontSize: '1.875rem',
		lineHeight: '2.25rem',
	},
	'text-lg': {
		fontSize: '1.125rem',
		lineHeight: '1.75rem',
	},
	'pre-line': {
		whiteSpace: 'pre-line',
	},
	bold: {
		fontWeight: 'bold',
	},
	'font-700': {
		fontWeight: '700',
	},
} as const satisfies Record<string, CSSProperties>;
const displays = {
	'inline-block': {
		display: 'inline-block',
	},
	'inline-flex': {
		display: 'inline-flex',
	},
} as const satisfies Record<string, CSSProperties>;
const borders = {
	'border-0': {
		border: 0,
	},
	rounded: {
		borderRadius: '0.25rem',
	},
	'rounded-sm': {
		borderRadius: '0.15rem',
	},
	'rounded-b': {
		borderBottomLeftRadius: '0.25rem',
		borderBottomRightRadius: '0.25rem',
	},
} as const satisfies Record<string, CSSProperties>;
const images = {
	'object-cover': {
		objectFit: 'cover',
	},
} as const satisfies Record<string, CSSProperties>;
const backgrounds = {
	'bg-white': {
		backgroundColor: 'white',
	},
} as const satisfies Record<string, CSSProperties>;
const transforms = {
	'transform-center-x': {
		transform: 'translateX(-50%)',
		msTransform: 'translateX(-50%)',
	},
	'transform-center-y': {
		transform: 'translateY(-50%)',
		msTransform: 'translateY(-50%)',
	},
	'transform-center-xy': {
		transform: 'translateX(-50%), translateY(-50%)',
		msTransform: 'translateX(-50%), translateY(-50%)',
	},
} as const satisfies Record<string, CSSProperties>;
export const styles = {
	...margins,
	...paddings,
	...positioning,
	...texts,
	...displays,
	...borders,
	...images,
	...dimensions,
	...backgrounds,
	...transforms,
} as const satisfies Record<string, CSSProperties>;

export const classes = applyClasses(styles);
