import {Extras} from "../../../types.js";


export default (extra: Extras.Combobox, config: Extras.Config.Combobox): {priceWithTax: number, priceWithoutTax: number}=>{
    return config.reduce((acc, choiceId)=>{        
        const choice = extra.options.choices.find(x=>x.id === choiceId);
        if(!choice){
            return {priceWithTax:0, priceWithoutTax:0};
        }
        return {priceWithTax: acc.priceWithTax+choice.priceWithTax ?? 0, priceWithoutTax: acc.priceWithoutTax+choice.priceWithoutTax ?? 0};
    }, {priceWithTax: 0, priceWithoutTax: 0});
}