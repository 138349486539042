import type {FC, CSSProperties} from "../types.js";

export default (function Radio({id, checked = false, name, className, onInput, style = {width: '3vw', height: '3vw'}}){
    const onChange = (ev: InputEvent)=>onInput?.((ev.target as HTMLInputElement).checked);
    return (
        <label style={{...style}} className={className}>
            <input type="radio" id={id} defaultChecked={checked} name={name} style={{opacity: 0}} onChange={onChange as any}/>
        </label>
    );
}) as FC<{id?: string, name?: string, className?: string, checked?: boolean, onInput?: (val: boolean)=>void, style?: CSSProperties}>

