type EntriesType = [PropertyKey, unknown][] | ReadonlyArray<readonly [PropertyKey, unknown]>;
type DeepWritable<OBJ_T> = { -readonly [P in keyof OBJ_T]: DeepWritable<OBJ_T[P]> };
type UnionToIntersection<UNION_T> = (UNION_T extends any ? (k: UNION_T) => void : never) extends ((k: infer I) => void) ? I : never;
type UnionObjectFromArrayOfPairs<ARR_T extends EntriesType> = DeepWritable<ARR_T> extends (infer R)[] ? R extends [infer key, infer val] ? { [prop in key & PropertyKey]: val } : never : never;
type MergeIntersectingObjects<ObjT> = {[key in keyof ObjT]: ObjT[key]};
type EntriesToObject<ARR_T extends EntriesType> = MergeIntersectingObjects<UnionToIntersection<UnionObjectFromArrayOfPairs<ARR_T>>>;

export const fromEntries = <ARR_T extends EntriesType>(iterable: ARR_T): EntriesToObject<ARR_T>=>{
    return [...iterable].reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
    }, {} as any) as EntriesToObject<ARR_T>;
}



type TupleEntry<T extends readonly unknown[], I extends unknown[] = [], R = never> =
    T extends readonly [infer Head, ...infer Tail] ?
        TupleEntry<Tail, [...I, unknown], R | [`${I['length']}`, Head]> :
        R

type ObjectEntry<T extends {}> =
    T extends object ?
        { [K in keyof T]: [K, Required<T>[K]] }[keyof T] extends infer E ?
            E extends [infer K, infer V] ?
                K extends string | number ?
                    [`${K}`, V] :
                    never :
                never :
            never :
        never

export type Entry<T extends {}> =
    T extends readonly [unknown, ...unknown[]] ?
        TupleEntry<T> :
        T extends ReadonlyArray<infer U> ?
            [`${number}`, U] :
            ObjectEntry<T>


const entries = <T extends {}>(obj: T): (ReadonlyArray<Entry<T>>)=>{
    const ownProps = Object.keys(obj) as (keyof T)[];
    let i: number = ownProps.length;
    const resArray = new Array<[keyof T, T[keyof T]]>(i);
    while (i--)
        resArray[i] = [ownProps[i] as keyof T, obj[ownProps[i] as keyof T]];

    return resArray as unknown as ReadonlyArray<Entry<T>>;
};
export const object = {
    entries,
    fromEntries
} as const;