const DEFAULT_KEYMAP = {
    ENTER: [13] as const,
    BACK: [32] as const,
    HOME: [72] as const,
    LANG: [76] as const,
    MESSAGES: [77] as const,
    CHECKOUT: [66] as const,
    ARROW_LEFT: [37] as const,
    ARROW_UP: [38] as const,
    ARROW_RIGHT: [39] as const,
    ARROW_DOWN: [40] as const,
    NUMBER_0: [48] as const,
    NUMBER_1: [49] as const,
    NUMBER_2: [50] as const,
    NUMBER_3: [51] as const,
    NUMBER_4: [52] as const,
    NUMBER_5: [53] as const,
    NUMBER_6: [54] as const,
    NUMBER_7: [55] as const,
    NUMBER_8: [56] as const,
    NUMBER_9: [57] as const,
} as const;


export const makeKeyboardEvent = <T extends (keyof typeof DEFAULT_KEYMAP)[]>(keyNames: T, handler: (keyName: T[number], ev: KeyboardEvent)=>(Partial<{preventDefault: boolean, stopPropagation: boolean}> | undefined)): (ev: KeyboardEvent)=>void=>{
    const codeToNameMap: Record<number, T[number]> = keyNames
        .reduce((acc, name)=>({...acc, ...(DEFAULT_KEYMAP[name] as readonly number[]).reduce((acc2, code)=>({...acc2, [code]: name}), {})}), {});
    return (ev: KeyboardEvent): void=>{
        const keyCode = ev.keyCode ?? ev.which;
        const keyName = codeToNameMap[keyCode];
        if (!keyNames.some(x=>x===keyName))
            return;
        const modifiers = handler(keyName, ev) ?? {};
        if (modifiers.stopPropagation){
            ev.stopImmediatePropagation();
        }
        if (modifiers.preventDefault) {
            ev.preventDefault();
        }
    }
}

