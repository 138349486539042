import { makeKeyboardEvent } from '#events';
import { getTranslation, px2vh, px2vw, translate } from '#utils';
import { useLayoutEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { Components } from '../../../types.js';
import type { Extras, Layouts, Signals } from '../../../types.js';
import { NumericExtraOutput } from './types.js';
import { classes } from '../../../stylesheet.js';
import type { CSSProperties, FC } from 'react';
import { printPrice } from '../../../utils.js';

const NumberInput = (({ isFocused = false, disabled = false, value }) => {
	return (
		<label
			tabIndex={0}
			style={{
				...classes('rounded'),
				display: 'inline-block',
				height: `${px2vh(56)}vh`,
				width: `${px2vw(60)}vw`,
				backgroundColor: '#eee',
				top: `calc(50% - ${px2vh(56) / 2}vh)`,
				color: 'inherit',
				right: `${px2vw(60) / 2}vw`,
				position: 'absolute',
			}}
		>
			<input disabled={disabled} type="number" value={value} style={{ opacity: 0 }} />
			<div
				style={{
					position: 'absolute',
					width: '100%',
					textAlign: 'center',
					top: 0,
					color: 'black',
					lineHeight: `${px2vh(56)}vh`,
				}}
			>
				{value}
			</div>
			{isFocused ? (
				<>
					<div
						className="icon-chevron-up"
						style={{
							position: 'absolute',
							fontSize: 'inherit',
							top: `-1.2em`,
							left: 'calc(50% - 0.5em)',
							color: 'black',
						}}
					></div>
					<div
						className="icon-chevron"
						style={{
							position: 'absolute',
							fontSize: 'inherit',
							bottom: `-1.2em`,
							left: 'calc(50% - 0.5em)',
							color: 'black',
						}}
					></div>
				</>
			) : null}
		</label>
	);
}) as FC<{ isFocused?: boolean; disabled?: boolean; value: number }>;

export default (({ data, injected, idList, id, style, signals, templating }) => {
	const [isInputFocused, setInputFocused] = useState(false);
	const [inputValue, setInputValue] = useState<number>((signals.config.value[data.extra.id] as number) ?? 0);
	//Cuando se pulsa enter quitamos el primer handler y colocamos el segundo
	const onKeyDown = makeKeyboardEvent(
		['ARROW_UP', 'ARROW_DOWN', 'ARROW_LEFT', 'ARROW_RIGHT', 'BACK', 'ENTER'],
		(key) => {
			if (!isInputFocused && key === 'ENTER') {
				setInputFocused(true);
				return { preventDefault: true, stopPropagation: true };
			}
			if (isInputFocused) {
				if (key === 'ARROW_UP' && inputValue < (data.extra.options.max || Infinity)) {
					setInputValue(inputValue + 1);
				} else if (key === 'ARROW_DOWN' && inputValue > (data.extra.options.min || 0)) {
					setInputValue(inputValue - 1);
				} else if (key === 'BACK' || key === 'ENTER') {
					if (inputValue === 0) {
						injected.configurator.removeExtra(data.extra.id);
					} else {
						injected.configurator.addExtra(data.extra as any, inputValue);
					}
					delete signals.config.value[data.extra.id];
					signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
					setInputFocused(false);
					return { stopPropagation: true };
				}
				return { preventDefault: true, stopPropagation: true };
			}
		}
	);

	useLayoutEffect(() => {
		const extraConfig = signals.cart.value?.[JSON.parse(localStorage.getItem('orderToken') || '[]')]?.filter(
			(product) => window.location.pathname.indexOf(product.timestamp) > -1
		)?.[0]?.config;
		if (extraConfig?.[data.extra.id]) {
			injected.configurator.addExtra(data.extra, extraConfig[data.extra.id] ?? 0);
			delete signals.config.value[data.extra.id];
			signals.config.value = { ...signals.config.value, ...injected.configurator.toJson() };
			//@ts-ignore
			setInputValue(extraConfig[data.extra.id]);
		}
	}, []);

	useLayoutEffect(() => {
		if (sessionStorage.getItem('resetExtras')) {
			setInputValue(0);
		}
	}, [sessionStorage.getItem('resetExtras')]);

	const price =
		sessionStorage.getItem('pricesIncludeTax') === 'true'
			? data.extra.options.priceWithTax
			: data.extra.options.priceWithoutTax;
	const currentOffset = idList.indexOf(id);
	const navMap = {
		up: currentOffset > 0 ? idList[currentOffset - 1].toString() : undefined,
		down: currentOffset !== idList.length - 1 ? idList[currentOffset + 1].toString() : undefined,
	};
	const onFocus = match(currentOffset)
		.with(0, () => (ev) => ev.target.parentElement.scrollTo({ top: 0 }))
		.with(idList.length - 1, () => (ev) => ev.target.parentElement.scrollTo({ top: Infinity }))
		.otherwise(() => undefined);
	return (
		<injected.Navigable navMap={navMap}>
			<div
				onFocus={onFocus}
				id={id}
				onKeyDown={onKeyDown}
				className={'navigable'}
				data-scroll-on-focus={true}
				style={{
					...classes('rounded', 'm-8', 'p-4', 'relative'),
					boxShadow: '0px 4px 16px 0px #95979A40',
					textAlign: 'left',
					...style,
					color: '#2E3843',
				}}
			>
				<div style={{ ...classes('inline-block', 'relative'), width: 'calc(100% - 2.5rem)' }}>
					<div
						style={{
							...classes('bold'),
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{getTranslation(
							data.extra.translations,
							templating.languageCode,
							templating.projectLanguageCode
						)}
					</div>
					<div>
						{translate(templating.texts, 'price-per-unit').replace(
							'{{price}}',
							`${printPrice(price / 100, data.options.currency_code)}`
						)}
					</div>
				</div>
				<NumberInput isFocused={isInputFocused} value={inputValue} />
			</div>
		</injected.Navigable>
	);
}) as FC<{
	data: { extra: z.infer<typeof NumericExtraOutput>; options: { currency_code: string } };
	id?: string;
	idList: (string | number)[];
	injected: {
		configurator: Extras.Configurator;
		Navigable: Components.Navigable;
	};
	signals: {
		config: Signals.ExtraConfig;
		cart: Signals.Cart;
	};
	style: CSSProperties;
	templating: Parameters<Layouts.Details>[0]['templating'];
}>;
