import {match} from "ts-pattern";
import type {Extras} from "../../../types.js";
import normal from "./normal.js";
import numeric from "./numeric.js";
import combobox from "./combobox.js";
import multicombobox from "./multicombobox.js";

type CalculatorReturnType = {priceWithTax: number, priceWithoutTax: number};
type Calculator =
    ((extra: Extras.Normal, config: any)=>CalculatorReturnType) |
    ((extra: Extras.Numeric, config: Extras.Config.Numeric)=>CalculatorReturnType) |
    ((extra: Extras.Combobox, config: Extras.Config.Combobox)=>CalculatorReturnType) |
    ((extra: Extras.Multicombobox, config: Extras.Config.Multicombobox)=>CalculatorReturnType);

const calculator = (extra: Parameters<Calculator>[0], config: Parameters<Calculator>[1])=>{
    return match(extra)
        .with({type: "normal"}, (extra: Extras.Normal)=>normal(extra))
        .with({type: "numeric"}, (extra: Extras.Numeric)=>numeric(extra, config))
        .with({type: "combobox"}, (extra: Extras.Combobox)=>combobox(extra, config))
        .with({type: "multicombobox"}, (extra: Extras.Multicombobox)=>multicombobox(extra, config))
        .run() as ReturnType<Calculator>;
};
export default calculator;